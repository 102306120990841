<template>
  <form @submit.prevent="saveTicketDetails" class="flex flex-wrap">
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Customer Name</label>
      <p class="bge-input-spacing rounded w-full">
        {{ ticket.contact_name }}
      </p>
    </div>
    <div v-if="customersEquipment.length > 0" class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Equipment</label>
      <div class="w-full relative">
        <select
          class="bge-input bge-select rounded"
          v-model="ticket.equipment_id"
        >
          <template v-for="item in customersEquipment">
            <option :key="item.equipment_id" :value="item.equipment_id">
              {{ item.name }} (SN:{{ item.serial_number }})
            </option>
          </template>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Ticket Title</label>
      <input
        v-model="ticket.title"
        required
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Severity <span class="text-red-500">*</span></label
      >
      <div class="inline-block relative w-full">
        <select
          v-model="ticket.severity"
          required
          class="bge-input bge-select rounded"
        >
          <option value="0">Normal</option>
          <option value="1">Low</option>
          <option value="2">Medium</option>
          <option value="3">High</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Contact Name (Additional)</label
      >
      <input
        v-model="ticket.contact_name_additional"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Contact Number (Additional)</label
      >
      <input
        v-model="ticket.contact_number_additional"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Description of Issue</label
      >
      <textarea
        v-model="ticket.description"
        class="bge-input bge-input-spacing rounded w-full"
        rows="10"
        type="text"
        placeholder="Issue Description"
      ></textarea>
    </div>

    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Follow Up At</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="ticket.follow_up_at"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>

    <div class="w-full">
      <button
        type="button"
        @click="saveTicketDetails"
        class="flex items-center float-right mt-5 rounded border bg-green-200 border-green-400 text-green-800 py-1 px-3 hover:bg-green-300 hover:border-green-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Update Service Ticket Details</span>
      </button>
    </div>
  </form>
</template>

<script>
const Datepicker = () => import("vuejs-datepicker");
export default {
  name: "EditServiceTicketDetail",
  components: {
    Datepicker,
  },
  data() {
    return {
      customersEquipment: [],
    };
  },
  props: {
    ticket: {
      required: true,
      type: Object,
    },
  },
  async mounted() {
    this.getEquipment();
  },
  methods: {
    saveTicketDetails: function() {
      this.$emit("complete");
    },
    getEquipment: async function() {
      try {
        let result = await this.ContactService.getEquipment(
          this.ticket.contact_id
        );
        this.customersEquipment = result.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
